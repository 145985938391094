import React, { useState } from "react";
import * as Accordion from "@radix-ui/react-accordion";

export const FAQen = () => {
  const [activeTab, setActiveTab] = useState("item1");

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  const getTabStyle = (tabName) => ({
    borderBottom: activeTab === tabName ? "2px solid #52c0e9" : "none",
    color: activeTab === tabName ? "#52c0e9" : "#ACBFC6",
    fontWeight: activeTab === tabName ? "normal" : "350",
  });

  return (
    <div className="tw-w-full tw-mt-16">
      <div className="tw-flex tw-justify-center tw-overflow-scroll tw-overflow-y-hidden md:tw-overflow-hidden tw-gap-4 tw-scrollbar-hidden">
        <button
          style={getTabStyle("item1")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item1")}>
          General Information
        </button>
        <button
          style={getTabStyle("item2")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item2")}>
          Points Earning
        </button>
        <button
          style={getTabStyle("item3")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item3")}>
          Members Benefit
        </button>
        <button
          style={getTabStyle("item4")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item4")}>
          Points Redemption
        </button>
        <button
          style={getTabStyle("item5")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item5")}>
          Pay with Points
        </button>
      </div>

      <div className="tw-mt-6">
        {activeTab === "item1" && (
          <div className="tw-flex tw-flex-col tw-gap-3">
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    Who are the participating brands for AYANA Rewards?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <ol
                    type="a"
                    className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1 tw-mb-0">
                    <li>
                      Participating Hotels, Resorts & Residences are AYANA
                      Midplaza Jakarta, Delonix Hotel Karawang, Delonix
                      Residences Karawang, AYANA Resort Bali, AYANA Segara Bali,
                      AYANA Villas Bali, RIMBA by AYANA Bali, and AYANA Komodo
                      Waecicu Beach.
                    </li>
                    <li>Other Participating Brand is Riverside Golf Club</li>
                  </ol>
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item2"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>How do I sign up for AYANA Rewards?</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  <span>
                    Members can sign up online via
                    <a
                      href="http://rewards.ayana.com/signup"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-ml-1">
                      https://rewards.ayana.com/signup
                    </a>
                  </span>
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 3: Membership Card */}
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    Will I receive a physical or digital membership card?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Currently, AYANA Rewards members do not provide physical or
                  digital membership cards. However, you can access your account
                  by logging in as a member through
                  <a
                    href="https://rewards.ayana.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tw-ml-1">
                    https://rewards.ayana.com/
                  </a>
                  .
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 4: Membership Benefits */}
              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    What benefits do I receive as an AYANA Rewards member?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  All AYANA Rewards members can enjoy the benefits outlined in
                  the "Member Benefits" section of the AYANA Rewards Portal
                  depending on the membership tier. However, please note that
                  certain benefits may be subject to specific terms and
                  conditions.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 5: Sharing Membership */}
              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    Can my partner and I share an AYANA Rewards membership?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  AYANA Rewards memberships are awarded on an individual basis.
                  Joint accounts for partners or multiple accounts for a single
                  person are not permitted.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 6: Point Transfers */}
              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I would like to transfer my points to another person. Is it
                    possible?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  We apologize to inform you that currently transferring points
                  to another person is prohibited.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 7: Points Expiration */}
              <Accordion.Item
                value="item7"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>Do Points Expire?</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  If members fail to maintain active status within 24 months
                  from the last activity, the account will be deactivated, and
                  all points earned will be forfeited.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 8: Maintaining Active Status */}
              <Accordion.Item
                value="item8"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>How am I able to maintain my active status?</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Members are able to maintain active status through website
                  login activity, completing a qualifying stay, making a
                  qualifying spend, or redeeming AYANA Rewards points.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 9: Contact Information */}
              <Accordion.Item
                value="item9"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>Does AYANA Rewards have a phone line?</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Currently, AYANA Rewards can only be contacted via email at
                  info@ayanarewards.com.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 10: Gold or Platinum Status */}
              <Accordion.Item
                value="item10"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    How am I able to maintain my Gold or Platinum status?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Members are required to retain <b>Level Points</b> or{" "}
                  <b>Eligible Nights</b> accumulated until December 31st of the
                  following calendar year since the last upgrade, downgrade, or
                  retention. Failure to meet the minimum points or nights
                  required will result in the downgrade of membership level.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 11: Upcoming Bookings */}
              <Accordion.Item
                value="item11"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I just completed a booking. Why is it not showing in my
                    Upcoming Bookings section?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Reservations and changes to reservations may take up to{" "}
                  <b>72 hours</b> to display in your Upcoming Bookings section.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 12: Member Rates */}
              <Accordion.Item
                value="item12"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I just signed up for AYANA Rewards or forgot to sign in as a
                    member. Can I get the member rates for my existing booking?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Members are ineligible to claim the member-exclusive room
                  discount if they sign up after booking a room or do not log in
                  when booking a room at the participating hotels.
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
        {activeTab === "item5" && (
          <div>
            <div className="tw-flex tw-flex-col tw-gap-3">
              <Accordion.Root collapsible type="single" className="">
                <Accordion.Item
                  value="item1"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      How does creating a booking with AYANA Rewards Points
                      work?
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                    <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                      When you choose this option, your points will be used to
                      cover the number of points required for your booking. You
                      can decide how many points you want to redeem to pay for
                      the booking and pay the remaining booking amount using the
                      other available payment methods.
                    </p>
                  </Accordion.Content>
                </Accordion.Item>

                <Accordion.Item
                  value="item2"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      Can I use my remaining points to pay for a room after I
                      booked my room before I notice this?
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    Unfortunately, members who have already booked a room cannot
                    utilize the Pay by Points feature. Pay by Points can only be
                    used when booking through our website with rates flagged as
                    "Eligible for AYANA Rewards Points."
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 3: Membership Card */}
                <Accordion.Item
                  value="item3"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      How many points do I need to use Pay with Points?
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    The minimum points required for Pay by Points is 500 reward
                    points.
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 4: Membership Benefits */}
                <Accordion.Item
                  value="item4"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      What is the value of Pay with Points per 1 point?
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    The value of Pay by Points may vary depending on the
                    participating brands. When utilizing Pay by Points, you can
                    view the value in the local currency.
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 5: Sharing Membership */}
                <Accordion.Item
                  value="item5"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>Can I cancel my booking if I paid using points?</span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    Your booking’s cancellation policy will follow the
                    cancellation policy from the rate you selected. If the
                    selected rate is refundable, the amount paid using points
                    will be refunded as points and the portion paid using
                    another payment method will be refunded according to your
                    payment method.
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 6: Point Transfers */}
                <Accordion.Item
                  value="item6"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      Can I use my points to pay for restaurant or spa charges?
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    Currently, we do not accept Pay by Points for restaurant or
                    spa charges.
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 7: Points Expiration */}
                <Accordion.Item
                  value="item7"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      Am I still able to earn points although I’m booking the
                      room with pay with points?
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    Yes, you are allowed to earn AYANA Rewards points.
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion.Root>
            </div>
          </div>
        )}
        {activeTab === "item4" && (
          <div>
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I would like to transfer the recipients of the redemption
                    voucher to someone else. Is it allowed?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                    Yes, it is allowed to transfer the recipients of the product
                    or services that you utilize to someone else. However,
                    members must individually inform AYANA Rewards Membership
                    Services prior to the utilization process.
                  </p>
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 3: Membership Card */}
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I just redeemed my redemption voucher. However, I now feel
                    like I would like to refund it. Is it possible?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Once a voucher has been redeemed, it is non-cancellable and
                  non-refundable.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 4: Membership Benefits */}
              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I do not have a printer to print out the redemption voucher.
                    What is the alternative method?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  In addition to a printed e-voucher, you may also present your
                  "Redemption Acknowledgement" email received from our system or
                  any confirmation email/letter from our member team, if
                  applicable.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 5: Sharing Membership */}
              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    Am I still eligible for membership tier benefits when
                    staying on Free-night Redemption?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Yes, you are entitled to all membership benefits that apply.
                  However, if you transfer the product/services to someone else,
                  the recipient will be categorized as a non-member and will not
                  receive any membership tier benefits.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 6: Point Transfers */}
              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>Will there be extra fees for my stay?</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Based on the hotel, the free-night redemption voucher may not
                  include extra-person charges and surcharge fees if your stay
                  falls under the high season or peak season period.
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 7: Points Expiration */}
              <Accordion.Item
                value="item7"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    Can I cancel or reschedule my confirmed free-night
                    redemption?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Yes, you can reschedule or cancel your free-night room
                  reservation as long as it aligns with the brand’s cancellation
                  deadline. However, please ensure that your rescheduled stay
                  falls within the timeframe before the voucher's expiration
                  date.
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item8"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    What does the redemption voucher expiration date mean?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  The expiration date signifies the last date a member can use
                  the voucher. For room reservations, it means the member must
                  check out before the expiration date. For services, the member
                  must utilize the voucher before the expiration date.
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item9"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>Why do I not receive my redemption voucher?</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Please contact us if you do not receive your redemption
                  vouchers. It might happen because you ever unsubscribe from
                  the mailing list.
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
        {activeTab === "item3" && (
          <div>
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    As an AYANA Rewards Gold or AYANA Rewards Platinum member,
                    is my complimentary 1-tier room upgrade guaranteed?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                    Unfortunately, complimentary 1-tier room upgrades cannot be
                    guaranteed before your arrival. They are subject to
                    availability and blackout dates are applied upon your
                    arrival at the participating brands.
                  </p>
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item2"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>What type of room upgrade will I receive?</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Your complimentary 1-tier room upgrade is applicable only
                  within the same room category that you initially booked. This
                  does not include upgrades such as room-to-suite,
                  multiple-bedroom upgrades (e.g., room to 2-bedroom), villas,
                  and residences.
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I noticed that I am entitled to early check-in and late
                    check-out. Is this guaranteed?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Early check-in and late check-out for AYANA Rewards Gold and
                  AYANA Rewards Platinum members are subject to availability
                  upon your arrival. Unfortunately, we cannot guarantee them
                  before your arrival.
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    As an AYANA Rewards Platinum member, I noticed that I am
                    entitled to Complimentary Breakfast, but I already booked a
                    room with breakfast included. Can I give this complimentary
                    benefit to another person?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  If a member has already booked a room with breakfast included,
                  they are automatically not entitled to any additional
                  complimentary breakfast benefit.
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I am an AYANA Rewards Platinum member who booked through
                    third-party sites (not direct booking). Am I still eligible
                    for Complimentary Breakfast?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Complimentary Breakfast for AYANA Rewards Platinum members is
                  exclusively available when a member books through direct
                  channels only.
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    What booking channels are classified as direct channels?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Direct channels refer to a room reservation made through the
                  official AYANA website (www.ayana.com), AYANA Rewards website
                  (https://rewards.ayana.com/), or via our Hotel Reservation
                  service.
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
        {activeTab === "item2" && (
          <div>
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>How do I earn points for my hotel stays?</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                    You can earn points on your hotel stays by booking a room at
                    Eligible Rates & Channels. Additionally, any incidental
                    charges eligible for points earned and settled to your room
                    will also earn points.
                  </p>
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item2"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    What happens if I pay my incidental charges (e.g.,
                    restaurant or spa charges) using other payment methods such
                    as cash or cards at the outlets? Can I still earn points?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Yes, you can still earn points on your incidental charges even
                  if you use other payment methods. Simply inform our staff
                  during bill settlement that you would like to earn points.
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I am not staying at the hotel. Can I still earn points from
                    my restaurant or spa charges?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Yes, you can earn points from your restaurant and spa charges
                  even if you are not staying at the brand. Simply inform our
                  staff during bill settlement, and they'll assist you in
                  earning points.
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I am staying at the Hotel with Ineligible Room Rates or
                    Ineligible booking channels, am I still able to earn points?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  You are ineligible to earn points for non-eligible Room Rates.
                  However, you may still earn points from eligible incidental
                  charges incurred during your stay. Just ensure that these
                  charges are settled in your room.
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I am booking and paying for a room at the hotel. However, I
                    am not physically staying at the hotel. Am I still able to
                    earn points?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  In order to earn points, you must physically stay at the
                  hotel. If you're only acting as the booker of the room and not
                  staying at the hotel, you are ineligible to earn points.
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I already booked a room at the participating brand, why have
                    my points not been credited to my membership account yet?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  The points will be credited to your membership account within
                  7 working days from your check-out date.
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item7"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>How can I calculate the points I earn?</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  Points are credited to members' accounts at a rate of 1 point
                  for every IDR 10,000 spent at participating brands, except for
                  Delonix Hotel Karawang, Delonix Residences Karawang, and
                  Riverside Golf Club, where points are earned at a rate of 1
                  point for every IDR 15,000 spent. Please note that taxes,
                  service charges, and gratuity are excluded from the points
                  earning calculation.
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item8"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I just signed up for AYANA Rewards after making my
                    reservation. Can I have my membership linked and get my
                    points credited?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  If a member books a room with the same email address
                  associated with their AYANA Rewards membership account, the
                  reservation will be linked to their AYANA Rewards account
                  within 72 hours of the booking date. Points will be credited
                  to the member's account up to 7 working days from the
                  check-out date.
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item9"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    I have past stays/transactions that I haven't claimed yet.
                    Can I still earn the points?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  You can claim points for past stays/transactions within 60
                  days from the check-out date and transaction date. Claims made
                  outside of this timeframe cannot be processed.
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item10"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    How many rooms can I earn points on my AYANA Rewards
                    account?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  <span>
                    You can earn points for up to 5 (five) rooms booked for the
                    same stay period, provided the following criteria are met:
                  </span>
                  <ul>
                    <li>The rooms are reserved in your name.</li>
                    <li>
                      You are physically staying at the hotel for which the
                      multiple-room booking was made
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item11"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    Why didn't I earn any Nights or Points on my previous stay?
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  <span>
                    There are several rates that do not qualify for points
                    credit as below:
                  </span>
                  <ul>
                    <li>Third party online or offline travel agency rates</li>
                    <li>
                      Group rates for events, meetings, conferences, or weddings
                    </li>
                    <li>Complimentary stays</li>
                    <li>
                      Free-night awards redeemed through AYANA Rewards vouchers
                    </li>
                    <li>
                      Wholesaler rates, tour operator rates, airline crew rates,
                      airline staff rates, room rates billed to a Master
                      Account, and commissionable rates.
                    </li>
                    <li>
                      Other special rates not mentioned above, such as family
                      and friends' rates, etc.
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
      </div>
    </div>
  );
};
